@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Roboto:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

html {
  --color-primary: hsl(220, 70%, 60%);
  --color-gray-dim: hsla(220, 15%, 40%, 0.04);
  --color-black: hsl(220, 25%, 20%);
  --color-gray: hsl(220, 15%, 40%);

  --font-family-regular: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  --font-family-mono: 'Roboto Mono', Menlo, Consolas, 'Roboto Mono',
  'Droid Sans Mono', 'Liberation Mono', Courier, monospace;

  height: 100%;
  font-size: 14px;
  line-height: 1.6;
}

body {
  padding: 0;
  height: 100%;
  margin: 0;
  overflow-x: hidden;

  background-color: var(--color-gray-dim);
  color: var(--color-gray);
  font-family: var(--font-family-regular);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  padding: 0 4px;
  font-family: var(--font-family-mono);
  background-color: #fff;
  border: 1px solid hsla(0, 0%, 0%, 0.05);
  border-radius: 2px;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

main {
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
}

@media (max-height: 845px) {
  main {
    align-items: flex-start;
  }
}

p:last-child {
  margin-bottom: 0;
}

p:first-of-type {
  margin-top: 0;
}

.relative {
  position: relative;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}
